import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useObserver } from 'mobx-react'

import { useStore } from './util/useStore'

import Login from './view/login'
import Loading from './component/Loading'
import Main from './view'

function App() {
    const { loginStore } = useStore()

    useEffect(() => {
        // console.log('检查token状态')
        loginStore.token && loginStore.getUserInfo()
        // eslint-disable-next-line
    }, [])

    return useObserver(() => {
        if (loginStore.token && loginStore.isLogining) {
            return <Loading />
        }
        return (
            <Switch>
                <Route path='/login'>{loginStore.loggedIn ? <Redirect to='/' /> : <Login />}</Route>
                <Route path='/'>{loginStore.loggedIn ? <Main /> : <Redirect to='/login' />}</Route>
            </Switch>
        )
    })
}

export default App
