import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaAngleRight, FaAngleDoubleRight, FaQuestion } from 'react-icons/fa'
import { useObserver } from 'mobx-react'

import { useStore } from '../../../util/useStore'

import OrderCard from './OrderCard'
import RiderCard from './RiderCard'
import GDMap from './Map'

const Text = styled.div`
    color: #8f8fa7;
    font-size: 12px;
    margin-left: 16px;
    margin-right: 16px;
`
const TitleWrap = styled.div`
    display: flex;
    margin-bottom: 12px;
`
const Title = styled.div`
    font-weight: bold;
    color: #8f8fa7;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        color: #00a6f3;
    }
`
const IconWrap = styled.div`
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    color: #8f8fa7;
`
const IconWrap2 = styled.div`
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const DispatchWrap = styled.div`
    display: flex;
    align-items: center;
`
const OrderWrap = styled.div`
    width: 336px;
    height: 216px;
    border: 1px dashed #c2c2c2;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`
// const RiderWrap = styled.div``
const BlankCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
    width: 320px;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    background-color: #fff; */
    border-radius: 8px;
    color: #a2a2a2;
`
const BlankText = styled.div`
    font-size: 14px;
`
const BlankIcon = styled.div`
    font-size: 32px;
`
const SuggestRiderWrap = styled.div`
    width: 100%;
    min-height: 160px;
    padding: 8px;
    border: 1px dashed #c2c2c2;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
`
const SubTitle = styled.div`
    color: #8f8fa7;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
`
const Bottom = styled.div`
    display: flex;
`
const OtherRiderWrap = styled.div``
const OtherRider = styled.div`
    width: 316px;
    height: 1096px;
    border: 1px dashed #c2c2c2;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 32px;
`
const MapWrap = styled.div``
const Map = styled.div`
    width: 1096px;
    height: 1096px;
    border: 1px dashed #c2c2c2;
    border-radius: 8px;
`
const BaseButton = styled.span`
    padding: 8px 16px;
    border: 2px solid #777;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    color: #777;
    transition: all 0.1s linear;
`
const MainButton = styled(BaseButton)`
    &:hover {
        color: #00a6f3;
        border-color: #00a6f3;
    }
`
const CancelButton = styled(BaseButton)`
    &:hover {
        color: red;
        border-color: red;
    }
`
const ButtonWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 32px;
`

function DispatchOrder({ order, back }) {
    const { orderStore, loginStore } = useStore()
    const [currentRider, setCurrentRider] = useState('')
    const [currentAlongOrderId, setCurrentAlongOrderId] = useState([])
    const [status, setStatus] = useState(0)
    useEffect(() => {
        orderStore.getRiderListNew({
            latitude: order.businessData.bussinessOrderLatitude,
            longitude: order.businessData.bussinessOrderLongitude,
        })
        // eslint-disable-next-line
    }, [])

    //推薦車手、其他車手
    const handleClickRider = (rider) => {
        setStatus(1)
        setCurrentRider(rider)
    }

    //確認選擇
    const handleClickAcceptSelect = () => {
        orderStore
            .getAlongOrder({
                riderId: currentRider.riderId,
                orderId: order.id,
                regionId: loginStore.userInfo.region.id,
            })
            .then((res) => {
                setStatus(2)
            })
    }

    //確認派單
    const handleClickAcceptDispatch = () => {
        orderStore
            .dispatchOrder({
                orderIds: [order.id, ...currentAlongOrderId],
                riderId: currentRider.riderId,
            })
            .then(() => back())
    }

    //順路單
    const handleClickAlongOrder = (id) => {
        if (currentAlongOrderId.includes(id)) {
            setCurrentAlongOrderId(currentAlongOrderId.filter((v) => v !== id))
        } else {
            setCurrentAlongOrderId([...currentAlongOrderId, id])
        }
    }

    return useObserver(() => {
        return (
            <>
                <TitleWrap>
                    <Title onClick={back}>訂單列表</Title>
                    <IconWrap>
                        <FaAngleRight></FaAngleRight>
                    </IconWrap>
                    <Title>派單</Title>
                </TitleWrap>
                <DispatchWrap>
                    <OrderWrap>
                        <OrderCard data={order} onClick={() => {}}></OrderCard>
                    </OrderWrap>
                    <Text>訂單</Text>
                    <IconWrap2>
                        <FaAngleDoubleRight></FaAngleDoubleRight>
                    </IconWrap2>
                    <Text>車手</Text>
                    <OrderWrap>
                        {currentRider ? (
                            <RiderCard data={currentRider} onClick={() => {}}></RiderCard>
                        ) : (
                            <BlankCard>
                                <BlankIcon>
                                    <FaQuestion></FaQuestion>
                                </BlankIcon>
                                <BlankText>請在下方選擇車手</BlankText>
                            </BlankCard>
                        )}
                    </OrderWrap>
                    <ButtonWrap>
                        {status === 1 ? (
                            <MainButton onClick={handleClickAcceptSelect}>確認選擇</MainButton>
                        ) : status === 2 ? (
                            <MainButton onClick={handleClickAcceptDispatch}>確認派單</MainButton>
                        ) : null}

                        <CancelButton onClick={back}>取消</CancelButton>
                    </ButtonWrap>
                </DispatchWrap>
                <SubTitle>{status === 2 ? '順路單' : '推薦車手'}</SubTitle>
                <SuggestRiderWrap>
                    {status === 2
                        ? orderStore.alongOrder.map((v, i) => (
                              <OrderCard
                                  key={v.id}
                                  data={v}
                                  onClick={handleClickAlongOrder}
                                  hasMargin={i === orderStore.alongOrder.length - 1 ? false : true}
                                  active={currentAlongOrderId.includes(v.id)}
                              ></OrderCard>
                          ))
                        : orderStore.nearRiderList.map((v) => (
                              <RiderCard key={v.riderId} data={v} onClick={handleClickRider}></RiderCard>
                          ))}
                </SuggestRiderWrap>
                <Bottom>
                    {status === 2 ? null : (
                        <OtherRiderWrap>
                            <SubTitle>其他車手</SubTitle>
                            <OtherRider>
                                {orderStore.otherRiderList.map((v) => (
                                    <RiderCard
                                        key={v.riderId}
                                        data={v}
                                        onClick={handleClickRider}
                                        hasMargin
                                    ></RiderCard>
                                ))}
                            </OtherRider>
                        </OtherRiderWrap>
                    )}

                    <MapWrap>
                        <SubTitle>地圖</SubTitle>
                        <Map>
                            <GDMap
                                data={{
                                    riderLongitude: currentRider.riderLon,
                                    riderLatitude: currentRider.riderLat,
                                    businessData: order.businessData,
                                    consumerData: order.consumerData,
                                    nearRiderList: orderStore.nearRiderList,
                                }}
                            ></GDMap>
                        </Map>
                    </MapWrap>
                </Bottom>
            </>
        )
    })
}
export default DispatchOrder
