import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    background-color: #fff;
    border-radius: 8px;
    color: #73798c;
`
const Top = styled.div`
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
`
const Avatar = styled.div`
    height: 70px;
    width: 70px;
    border-radius: 4px;
    background-color: #00a6f3;
    margin-right: 16px;
    background-image: url(${(props) => props.avatar});
    background-size: contain;
    background-repeat: no-repeat;
`
const Info = styled.div`
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`
const Name = styled.div``
const Phone = styled.div`
    font-size: 14px;
`
const TagWrap = styled.div``
const Tag = styled.span`
    background-color: #fcd24d;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 12px;
`
const PackWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 16px;
`
const PackTitle = styled.div``
const PackNum = styled.div``
const Wait = styled.span`
    color: #b7291d;
`
const Sending = styled.span`
    color: #62ba2f;
`
const Total = styled.span``
const Bottom = styled.div`
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 8px 16px;
`
const LineA = styled.div`
    height: 8px;
    width: 40%;
    border-radius: 8px;
    background-color: #a2d6f0;
    margin-bottom: 6px;
`
const LineB = styled.div`
    height: 8px;
    width: 40%;
    border-radius: 8px;
    background-color: #f1602b;
`
const TimeWrap = styled.div`
    display: flex;
    margin-top: 8px;
`
// const TimeA = styled.div`
//     font-size: 14px;
//     margin-right: 16px;
//     color: #a2d6f0;
// `
// const TimeB = styled.div`
//     font-size: 14px;
//     color: #f1602b;
// `

// const toHHmmss = function (data) {
//     let hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
//     let minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60))
//     let seconds = ((data % (1000 * 60)) / 1000).toFixed(0)
//     return (
//         (hours < 10 ? '0' + hours : hours) +
//         ':' +
//         (minutes < 10 ? '0' + minutes : minutes) +
//         ':' +
//         (seconds < 10 ? '0' + seconds : seconds)
//     )
// }

const riderWorkType = ['全職', '兼職']
// const riderType = ['車手', '步兵']

function RiderCard({ data }) {
    return (
        <Wrap>
            <Top>
                <Avatar avatar={data.riderAvatar}></Avatar>
                <Info>
                    <Name>{data.riderName}</Name>
                    <Phone>{data.riderTel}</Phone>
                    <TagWrap>
                        <Tag>
                            {data.riderType === 4 ? '車手' : '步兵'}-{riderWorkType[data.riderWorkType - 1]}
                        </Tag>
                    </TagWrap>
                </Info>
                <PackWrap>
                    <PackTitle>待 / 送 / 總</PackTitle>
                    <PackNum>
                        <Wait>{data.orderWait}</Wait> / <Sending>{data.orderSend}</Sending> /
                        <Total>{data.orderAll}</Total>
                    </PackNum>
                </PackWrap>
            </Top>
            <Bottom>
                <LineA></LineA>
                <LineB></LineB>
                <TimeWrap>
                    {/* <TimeA>上線：{toHHmmss(data.riderOnlineTime)}</TimeA>
                        <TimeB>超時：{toHHmmss(data.riderTimeOut)}</TimeB> */}
                </TimeWrap>
            </Bottom>
        </Wrap>
    )
}

export default RiderCard
