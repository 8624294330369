import React from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'

import IndexPage from './IndexPage'
import RiderManage from './RiderManage'
import DataCenter from './DataCenter'
import Header from '../component/Header'

const Wrap = styled.div`
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: #eee;
    padding: 0 32px 32px;
`

function Main() {
    return (
        <Wrap>
            <Header></Header>
            <Switch>
                <Route path='/rider-manage'>
                    <RiderManage></RiderManage>
                </Route>
                <Route path='/data-center'>
                    <DataCenter></DataCenter>
                </Route>
                <Route path='/'>
                    <IndexPage></IndexPage>
                </Route>
            </Switch>
        </Wrap>
    )
}

export default Main
