import React from 'react'
import styled from 'styled-components'
import { FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa'

import OrderCard from './OrderCard'
import RiderCard from './RiderCard'
import GDMap from './Map'

const Text = styled.div`
    color: #8f8fa7;
    font-size: 12px;
    margin-left: 16px;
    margin-right: 16px;
`
const TitleWrap = styled.div`
    display: flex;
    margin-bottom: 12px;
`
const Title = styled.div`
    font-weight: bold;
    color: #8f8fa7;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        color: #00a6f3;
    }
`
const IconWrap = styled.div`
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    color: #8f8fa7;
`
const IconWrap2 = styled.div`
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const DispatchWrap = styled.div`
    display: flex;
    align-items: center;
`
const OrderWrap = styled.div`
    width: 336px;
    height: 216px;
    border: 1px dashed #c2c2c2;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`
// const RiderWrap = styled.div``
const SubTitle = styled.div`
    color: #8f8fa7;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
`
const Map = styled.div`
    width: 1096px;
    height: 1096px;
    border: 1px dashed #c2c2c2;
    border-radius: 8px;
`
const Exce = styled.div`
    font-size: 12px;
    color: #8f8fa7;
`

function OrderDetail({ order, back }) {
    return (
        <>
            <TitleWrap>
                <Title onClick={back}>訂單列表</Title>
                <IconWrap>
                    <FaAngleRight></FaAngleRight>
                </IconWrap>
                <Title>訂單詳情</Title>
            </TitleWrap>
            <DispatchWrap>
                <OrderWrap>
                    <OrderCard data={order}></OrderCard>
                </OrderWrap>
                <Text>訂單</Text>
                <IconWrap2>
                    <FaAngleDoubleRight></FaAngleDoubleRight>x
                </IconWrap2>
                <Text>車手</Text>
                <OrderWrap>
                    {order.riderData.constructor === Object ? (
                        <RiderCard data={order.riderData}></RiderCard>
                    ) : (
                        <Exce>异常</Exce>
                    )}
                </OrderWrap>
            </DispatchWrap>
            <SubTitle>地圖</SubTitle>
            <Map>
                <GDMap
                    riderLongitude={
                        order.riderData.constructor === Object
                            ? order.riderData.riderLon
                            : order.businessData.bussinessOrderLongitude
                    }
                    riderLatitude={
                        order.riderData.constructor === Object
                            ? order.riderData.riderLat
                            : order.businessData.bussinessOrderLatitude
                    }
                    businessLongitude={order.businessData.bussinessOrderLongitude}
                    bussinessLatitude={order.businessData.bussinessOrderLatitude}
                    consumerLongitude={order.consumerData.consumerAddressLongitude}
                    consumerLatitude={order.consumerData.consumerAddressLatitude}
                    logo={order.businessData.logo}
                ></GDMap>
            </Map>
        </>
    )
}
export default OrderDetail
