import React, { createContext } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import * as serviceWorker from './serviceWorker'

import App from './App'

import { store } from './store'

export const storeContext = createContext(null)

const GlobalStyle = createGlobalStyle`
    html, body, #root {
        height: 100%;
    }
    body {
        margin: 0;
    }
    h1, h2, h3,h4{
        margin-top: 0;
        margin-bottom: 0;
    }
    ul{
        padding:0;
        margin:0;
    }
    button {
        margin:0;
        padding: 0;
        outline:none;
    }
    a {
        color: #333;
        text-decoration: none;
    }
`

render(
    <>
        <Router>
            <storeContext.Provider value={store}>
                <App />
            </storeContext.Provider>
        </Router>
        <GlobalStyle />
    </>,
    document.getElementById('root')
)

serviceWorker.unregister()
