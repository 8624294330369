import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'

import Menu from './Menu'
import { FaUserTie } from 'react-icons/fa'

const Container = styled.div``
const FaWrap = styled.div`
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 20px;
    padding: 12px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    color: #8f8fa7;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
`

function Logout() {
    const [isInfoMenu, setIsInfoMenu] = useState(false)

    const handleClickInfoMenu = () => {
        setIsInfoMenu(!isInfoMenu)
    }
    const handleCloseMenu = () => {
        setIsInfoMenu(false)
    }
    const tagRef = useRef()

    return useObserver(() => (
        <Container>
            <FaWrap ref={tagRef} onMouseDown={handleClickInfoMenu} title='个人中心'>
                <FaUserTie></FaUserTie>
            </FaWrap>
            {isInfoMenu && <Menu close={handleCloseMenu} />}
        </Container>
    ))
}

export default Logout
