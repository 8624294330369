import React from 'react'
import styled from 'styled-components'
import { FaUserFriends, FaHome, FaChartLine } from 'react-icons/fa'

import StyledNavLink from '../StyledNavLink'

const Wrap = styled.div`
    display: flex;
    width: 500px;
    align-items: center;
`
const Button = styled.div`
    /* background-image: linear-gradient(127deg, #e6e7ed 0%, #f7f8fa 100%);
    box-shadow: -10px -10px 42px 0 #fafcfc, 10px 10px 40px 0 #bdc1d1, inset 1px 1px 9px 0 #e6e7eb; */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    background-color: #fff;
    border-radius: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #8f8fa7;
    font-size: 26px;
    transition: all 0.1s ease-in-out;
    padding: 8px 16px;
    margin-right: 16px;
`
const Title = styled.div`
    font-size: 12px;
    margin-left: 12px;
`

const StyledLink = styled(StyledNavLink)`
    display: inline-block;

    & ${Button} {
        color: #45bcff;
        /* background: #ebecf0;
        box-shadow: 1px 1px 5px 0 #ebecf0, inset -2px -2px 3px 0 #fcfeff, inset -10px -10px 31px 0 #ffffff,
            inset 3px 5px 22px 0 #bdc1d1; */
    }
`

function IndexPage() {
    return (
        <Wrap>
            <StyledLink to='/' exact>
                <Button>
                    <FaHome></FaHome>
                    <Title>首頁</Title>
                </Button>
            </StyledLink>
            <StyledLink to='/rider-manage'>
                <Button>
                    <FaUserFriends></FaUserFriends>
                    <Title>車手帳號</Title>
                </Button>
            </StyledLink>
            <StyledLink to='/data-center'>
                <Button>
                    <FaChartLine></FaChartLine>
                    <Title>數據中心</Title>
                </Button>
            </StyledLink>
        </Wrap>
    )
}

export default IndexPage
