import React from 'react'
import styled from 'styled-components'

const ButtonSuccess = styled.button`
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: ${(props) => props.option.color};
    background-color: ${(props) => props.option.bgColor};
    :disabled {
        cursor: not-allowed;
    }
`
const Span = styled.span``

function BtnSuccess({ option, children, onClick }) {
    return (
        <ButtonSuccess onClick={onClick} option={option}>
            <Span>{children}</Span>
        </ButtonSuccess>
    )
}

export default BtnSuccess
