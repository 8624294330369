import { observable, action } from 'mobx'

import api, { instance } from '../api'
import Toast from '../component/Toast'

class LoginStore {
    @observable captchaData = {
        data: '',
        message: '',
        success: false,
    }
    @observable loginMessage = {
        phoneMsg: '',
        captchaMsg: '',
    }

    @observable isLogining = true
    @observable loggedIn = false
    @observable token = JSON.parse(localStorage.getItem('token'))
    @observable userInfo = {}

    //获取验证码
    @action async postMessage(data) {
        const res = await api.login.postMessage(data)
        if (res.success) {
            this.captchaData = res
        } else {
            this.loginMessage.captchaMsg = res.message
        }
    }

    //登录
    @action async postLogin(data) {
        try {
            const res = await api.login.postLogin(data)
            if (res.success) {
                localStorage.setItem('token', JSON.stringify(res.data.token))
                sessionStorage.setItem(
                    'captcTime',
                    JSON.stringify({ count: 30, countion: false, code: this.captchaData.data })
                )
                localStorage.setItem('userinfo', JSON.stringify(res.data.webUser))
                this.userInfo = res.data.webUser
                instance.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`
                this.loggedIn = true
                // this.getUserInfo()
            } else if (res.code === 20003) {
                Toast.warning(res.message) //之后可能有其它处理
            } else if (res.code === 50001) {
                Toast.warning(res.message) //之后可能有其它处理
            } else {
                Toast.warning(res.message)
            }
        } catch (err) {}
    }

    //退出登录
    @action async postLogout() {
        try {
            const res = await api.login.postLogout()
            if (res.success) {
                localStorage.removeItem('token')
                localStorage.removeItem('userinfo')
                this.loggedIn = false
            }
        } catch (err) {}
    }

    // 获取用户信息
    @action async getUserInfo() {
        const res = await api.login.getUserInfo()
        if (res.success) {
            this.userInfo = res.data
            this.loggedIn = true
            this.isLogining = false
        } else {
            this.loggedIn = false
            this.isLogining = false
        }
    }
}

export const loginStore = new LoginStore()
