import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BtnSuccess from '../../component/Button/btnSuccess'
import { useObserver } from 'mobx-react'

import { useStore } from '../../util/useStore'
import { useInterval } from '../../util/useInterval'

// import Select from '../../component/Select'

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Wrap = styled.div`
    width: 450px;
    margin: 0 auto;
    border-radius: 6px;
    transition: 0.2s;
    padding: 24px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
`
const Package = styled.div``
// const SelectWrap = styled.div`
//     min-width: 140px;
// `
const PhoneWrap = styled.div`
    width: 350px;
    margin: 25px auto;
`

const H2 = styled.h2`
    margin: 20px 0px 40px 0px;
    text-align: center;
`
const TextError = styled.div`
    color: red;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 5px;
`
const Label = styled.label`
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
`
const Input = styled.input`
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    :focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
`
const ButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 5px 22px;
`

function Login() {
    const { loginStore } = useStore()
    const [phoneValue, setPhoneValue] = useState('')
    const [captcha, setCaptcha] = useState('')
    const [counting, setCounting] = useState({
        count: 30,
        countion: false,
        code: '',
    })
    // const [selectData] = useState([
    //     { id: 1, name: '+86 中国大陆' },
    //     { id: 2, name: '+886 中国台湾 ' },
    //     { id: 3, name: '+852 中国香港' },
    //     { id: 4, name: '+853 中国澳门' },
    // ])

    useEffect(() => {
        if (sessionStorage.getItem('captcTime')) {
            let data = JSON.parse(sessionStorage.getItem('captcTime'))
            loginStore.captchaData.data = data.code
            setCounting(data)
        }
        // eslint-disable-next-line
    }, [])

    //验证码到时器
    const countDown = () => {
        setCounting((state) => {
            if (state.count === 0) {
                loginStore.loginMessage.captchaMsg = ''
                sessionStorage.setItem(
                    'captcTime',
                    JSON.stringify({ count: 30, countion: false, code: loginStore.captchaData.data })
                )
                return { count: 30, countion: false, code: loginStore.captchaData.data }
            }
            sessionStorage.setItem(
                'captcTime',
                JSON.stringify({ count: state.count - 1, countion: true, code: loginStore.captchaData.data })
            )
            return { count: state.count - 1, countion: true, code: loginStore.captchaData.data }
        })
    }

    //use验证码到时器
    useInterval(countDown, counting.countion ? 1000 : null)

    //输入手机号
    const handleChangePhone = (e) => {
        setPhoneValue(e.target.value)
    }

    //输入验证码
    const handleChangeMessage = (e) => {
        setCaptcha(e.target.value)
    }

    //验证
    const checkForm = () => {
        // if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phoneValue)) {
        //     loginStore.loginMessage.phoneMsg = '請輸入正確手機號！'
        //     return false
        // } else {
        //     loginStore.loginMessage.phoneMsg = ''
        // }
        if (captcha === '') {
            loginStore.loginMessage.captchaMsg = '請輸入短信驗證碼！'
            return false
            // } else if (captcha !== loginStore.captchaData.data) {
            //     loginStore.loginMessage.captchaMsg = '短信驗證碼有誤！'
            //     return false
        } else {
            loginStore.loginMessage.captchaMsg = ''
        }
        return true
    }

    //获取验证码
    const handleClickMessage = () => {
        if (counting.countion) {
            loginStore.loginMessage.captchaMsg = '驗證碼已發送！'
            return
        }
        // if (/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phoneValue)) {
        if (true) {
            setCounting({ ...counting, countion: true })
            loginStore.loginMessage.phoneMsg = ''
            loginStore.loginMessage.captchaMsg = ''
            loginStore.postMessage({ tel: phoneValue })
        } else {
            loginStore.loginMessage.phoneMsg = '請輸入正確手機號！'
        }
    }

    //登录
    const handleClickLogin = () => {
        if (checkForm()) {
            loginStore.postLogin({ tel: phoneValue, code: captcha })
        }
    }

    return useObserver(() => (
        <Container>
            <Wrap>
                <Package>
                    {/* <SelectWrap>
                    <Select value={area} data={selectData} onChange={handleChangeArea} placeholder='选区号'></Select>
                </SelectWrap> */}
                    <PhoneWrap>
                        <H2>登錄頁面</H2>
                        <Label htmlFor='phone'>手機號：</Label>
                        <Input
                            id='phone'
                            type='text'
                            value={phoneValue}
                            placeholder='請輸入手機號'
                            onChange={(event) => handleChangePhone(event)}
                        ></Input>
                        <TextError>{loginStore.loginMessage.phoneMsg}</TextError>
                    </PhoneWrap>
                </Package>
                <Package>
                    <PhoneWrap>
                        <Label htmlFor='checkCode'>驗證碼：</Label>
                        <Input
                            id='checkCode'
                            type='text'
                            value={captcha}
                            placeholder='請輸入驗證碼'
                            onChange={(event) => handleChangeMessage(event)}
                        ></Input>
                        <TextError>{loginStore.loginMessage.captchaMsg}</TextError>
                        <BtnSuccess option={{ color: '#fff', bgColor: '#5bc0de' }} onClick={handleClickMessage}>
                            獲取驗證碼{counting.count}
                        </BtnSuccess>
                    </PhoneWrap>
                </Package>
                <Package>
                    <ButtonWrap>
                        <BtnSuccess onClick={handleClickLogin} option={{ color: '#fff', bgColor: '#5cb85c' }}>
                            登錄
                        </BtnSuccess>
                    </ButtonWrap>
                </Package>
            </Wrap>
        </Container>
    ))
}

export default Login
