import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
    /* background-image: linear-gradient(136deg, #e6e7ed 0%, #f7f8fa 100%);
    box-shadow: -7px -7px 16px 0 #fafbfc, 4px 3px 19px 0 #bdc1d1, inset -1px -1px 16px 0 #f5f6fa,
        inset 1px 1px 16px 0 #e9eaf2; */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    border-radius: 8px;
    padding: 16px;
    display: inline-block;
    background-color: #fff;
`

function Box({ children, ...props }) {
    return <Wrap {...props}>{children}</Wrap>
}

export default Box
