import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'

import { useStore } from '../../../util/useStore'
import Box from '../../../component/BoxA'

import OrderCard from './OrderCard'
import Loading from '../../../component/Loading'

const Wrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
`
const Title = styled.div`
    margin-bottom: 12px;
    font-weight: bold;
    color: #8f8fa7;
    font-size: 14px;
`
const NoData = styled.div`
    width: 100%;
    font-size: 12px;
    color: #73798c;
    text-align: center;
`
const LoadingWrap = styled(Box)`
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
`

function OrderList({ onClickOrder }) {
    const { loginStore, orderStore } = useStore()
    useEffect(() => {
        orderStore.shouldInitOrderList &&
            orderStore.getOrderList({ stationOrderStatus: 1, regionId: loginStore.userInfo.region.id })
        orderStore.shouldInitOrderList = false
        // eslint-disable-next-line
    }, [])

    //点击全部订单列表
    const handleClick = (order) => {
        onClickOrder(order)
    }

    return useObserver(() => {
        return (
            <>
                <Title>訂單列表</Title>
                {orderStore.gettingOrderList ? (
                    <LoadingWrap>
                        <Loading></Loading>
                    </LoadingWrap>
                ) : orderStore.orderList.length > 0 ? (
                    <Wrap>
                        {orderStore.orderList.map((v, i) => (
                            <OrderCard key={v.id} data={v} index={i} active={v.id} onClick={handleClick}></OrderCard>
                        ))}
                    </Wrap>
                ) : (
                    <LoadingWrap>
                        <NoData>暂无数据</NoData>
                    </LoadingWrap>
                )}
            </>
        )
    })
}

export default OrderList
