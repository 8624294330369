import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AMapLoader from '@amap/amap-jsapi-loader'

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`
const MapContainer = styled.div`
    width: 100%;
    height: 100%;
`

function Map({ data }) {
    const [map, setMap] = useState(null)
    const [AMap, setAMap] = useState(null)
    const [riding1, setRiding1] = useState(null)
    const [riding2, setRiding2] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        AMapLoader.load({
            key: 'e1e00abec9259d1aebd3ffe297241bf9', // 申请好的Web端开发者Key，首次调用 load 时必填
            version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            // plugins: ['AMap.ToolBar', 'AMap.Riding', 'AMap.Walking', 'AMap.CircleEditor', 'AMap.InfoWindow'], //插件列表
            plugins: ['AMap.Riding'], //插件列表
        })
            .then((AMap) => {
                let map = new AMap.Map('map-container1', {
                    zoom: 14, //级别
                    center: [data.businessData.bussinessOrderLongitude, data.businessData.bussinessOrderLatitude], //中心点坐标
                })
                let riding1 = new AMap.Riding({
                    map: map,
                    autoFitView: false,
                    hideMarkers: true,
                })
                setRiding1(riding1)
                let riding2 = new AMap.Riding({
                    map: map,
                    autoFitView: false,
                    hideMarkers: true,
                })
                setRiding2(riding2)
                setMap(map)
                setAMap(AMap)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
            })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!loading) {
            //商家
            let businessPosition = new AMap.LngLat(
                data.businessData.bussinessOrderLongitude,
                data.businessData.bussinessOrderLatitude
            )
            let businessMarker = new AMap.Marker({
                position: businessPosition,
                icon: new AMap.Icon({
                    imageSize: new AMap.Size(30, 30),
                    image: data.businessData.logo,
                }),
            })

            //终点
            let consumerPosition = new AMap.LngLat(
                data.consumerData.consumerAddressLongitude,
                data.consumerData.consumerAddressLatitude
            )
            let consumerMarker = new AMap.Marker({
                position: consumerPosition,
                icon: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
            })

            map.clearMap()

            //附近车手
            if (data.nearRiderList.length > 0) {
                for (let i = 0; i < data.nearRiderList.length; i++) {
                    map.add(
                        new AMap.Marker({
                            position: new AMap.LngLat(data.nearRiderList[i].riderLon, data.nearRiderList[i].riderLat),
                            icon: new AMap.Icon({
                                imageSize: new AMap.Size(30, 30),
                                image: data.nearRiderList[i].riderAvatar,
                            }),
                        })
                    )
                }
            }

            //圆
            let circle = new AMap.Circle({
                center: new AMap.LngLat(
                    data.businessData.bussinessOrderLongitude,
                    data.businessData.bussinessOrderLatitude
                ), // 圆心位置
                radius: 250, //半径
                strokeOpacity: 1, //线透明度
                strokeWeight: 3, //线粗细度
                fillOpacity: 0.35, //填充透明度
            })
            circle.setMap(map)

            //线路
            if (data.riderLongitude) {
                let riderPosition = new AMap.LngLat(data.riderLongitude, data.riderLatitude)
                let riderMarker = new AMap.Marker({
                    position: riderPosition,
                    icon: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
                })

                map.add([riderMarker, consumerMarker, businessMarker])
                map.setFitView([riderMarker, consumerMarker, businessMarker], true, [60, 60, 60, 60], 20)
                riding1.search(riderPosition, businessPosition)
            } else {
                map.add([consumerMarker, businessMarker])
                map.setFitView([consumerMarker, businessMarker], true, [60, 60, 60, 60], 20)
            }
            riding2.search(businessPosition, consumerPosition)
        }

        // eslint-disable-next-line
    }, [loading, data.riderLongitude, data.nearRiderList])

    return (
        <Wrap>
            <MapContainer id='map-container1'></MapContainer>
        </Wrap>
    )
}

export default Map
