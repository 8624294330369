import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'

import { useStore } from '../../util/useStore'

// import AreaSelector from './AreaSelector'
import OrderStatus from './OrderStatus'
import OrderList from './OrderList'
// import RiderList from './RiderList'
// import Location from './Location'
import DispatchOrder from './DispatchOrder'
import OrderDetail from './OrderDetail'

const Wrap = styled.div`
    /* display: flex;
    flex-direction: column; */
    height: 100%;
`

function IndexPage() {
    const { wsStore, orderStore } = useStore()
    const [currentOrder, setCurrentOrder] = useState({})
    const [dispatchStatus, setDispatchStatus] = useState(0)

    useEffect(() => {
        !wsStore.ws && wsStore.connectWs()
        // wsStore.getStationOrderStatus({ regionId: loginStore.userInfo.region.id })
        // eslint-disable-next-line
    }, [])
    const handleClickOrder = (order) => {
        console.log('订单：', order)
        setCurrentOrder(order)
        if (orderStore.currentStatus === 0) {
            setDispatchStatus(1)
        } else {
            setDispatchStatus(-1)
        }
    }
    const handleClickStatus = (index) => {
        if (dispatchStatus !== 0) {
            setDispatchStatus(0)
        }
        if (orderStore.currentStatus !== index) {
            orderStore.changeCurrentStatus(index)
        }
    }
    const handleBack = () => {
        setDispatchStatus(0)
    }
    return useObserver(() => {
        return (
            <Wrap>
                <OrderStatus onClickStatus={handleClickStatus}></OrderStatus>
                {/* <Section>
                    <AreaSelector></AreaSelector>
                </Section> */}
                {dispatchStatus === 0 ? (
                    <OrderList onClickOrder={handleClickOrder}></OrderList>
                ) : dispatchStatus === 1 ? (
                    <DispatchOrder order={currentOrder} back={handleBack}></DispatchOrder>
                ) : (
                    <OrderDetail order={currentOrder}></OrderDetail>
                )}

                {/* <RiderList></RiderList> */}
                {/* <Location
                        bussinessOrder={[
                            riderStore.coordinateData.bussinessOrderLongitude,
                            riderStore.coordinateData.bussinessOrderLatitude,
                        ]}
                        consumerAddress={[
                            riderStore.coordinateData.consumerAddressLongitude,
                            riderStore.coordinateData.consumerAddressLatitude,
                        ]}
                    ></Location> */}
            </Wrap>
        )
    })
}

export default IndexPage
