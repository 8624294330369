import React from 'react'
import styled from 'styled-components'

import Nav from './Nav'
import Logout from '../../view/login/Logout'

const Wrap = styled.div`
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const LogoutWrap = styled.div``
function Header() {
    return (
        <Wrap>
            <Nav></Nav>
            <LogoutWrap>
                <Logout></Logout>
            </LogoutWrap>
        </Wrap>
    )
}

export default Header
