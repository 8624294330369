import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div``

function DataCenter() {
    return <Wrap>data center</Wrap>
}

export default DataCenter
