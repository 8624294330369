import React from 'react'
import styled from 'styled-components'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Wrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #108ee9;
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    @keyframes loading {
        form {
            transform: rotate(0turn);
        }
        to {
            transform: rotate(1turn);
        }
    }
`

function Loading() {
    return (
        <Container>
            <Wrap>
                <AiOutlineLoading3Quarters></AiOutlineLoading3Quarters>
            </Wrap>
        </Container>
    )
}

export default Loading
