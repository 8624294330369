import { action, observable } from 'mobx'
import WebsocketHeartbeatJs from 'websocket-heartbeat-js'

import { orderStore } from './order'
const statusList = [
    'unsendOrderList',
    'unacceptOrderList',
    'acceptOrderList',
    'arriveStoreOrderList',
    'leaveStoreOrderList',
    'arriveTagOrderList',
    'finishOrderList',
    'unusualOrderList',
]
class WsStore {
    @observable isOrderListing = true
    @observable orderList = []
    @observable isRiderOrderListing = true
    @observable riderOrderList = []
    @observable ws = ''

    //websocket链接
    @action connectWs() {
        this.ws = new WebsocketHeartbeatJs({
            // url: 'ws://192.168.1.161:8888/websocket', //测试服务
            // url: 'ws://192.168.1.118:8888/websocket',
            //url: 'ws://192.168.1.194:8888/websocket', //liufu
             url: 'wss://miracleeat.miraclegenesis.com/websocket',

            pingTimeout: 15000,
            pongTimeout: 10000,
            reconnectTimeout: 2000,
            pingMsg: JSON.stringify({ type: 'HEART_BEAT', data: 'ping' }),
            repeatLimit: 2,
        })

        this.ws.onopen = () => {
            this.ws.send(
                JSON.stringify({
                    type: 'AUTH',
                    token: JSON.parse(localStorage.getItem('token')),
                })
            )
            this.getAllOrderStatus()
        }

        this.ws.onmessage = (e) => {
            let data = JSON.parse(e.data)

            if (data.type === 'CLOSE') {
                this.ws.close()
            } else if (data.type === 'API') {
                switch (data.data.type) {
                    case 'orderStatus':
                        orderStore.allOrderStatus = data.data.orderStatus
                        break
                    case 'riderOrderList':
                        orderStore.orderList = data.data.riderOrderList[statusList[orderStore.currentStatus]]
                        break
                    default:
                        break
                }
            }
        }

        this.ws.onreconnect = function () {
            console.log('reconnecting...')
        }
    }

    @action getAllOrderStatus() {
        this.ws.send(
            JSON.stringify({
                type: 'API',
                dataType: 'GET_ALL_ORDER_STATUS',
            })
        )
    }
}

export const wsStore = new WsStore()
