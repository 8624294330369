import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    background-color: #fff;
    border-radius: 6px;
    margin-right: ${(props) => (props.hasMargin ? '16px' : 0)};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    color: #767e93;
    border: 2px solid ${(props) => (props.active ? '#00a6f3' : 'transparent')};
    flex-shrink: 0;
    margin-bottom: 16px;
`
const Left = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #eee;
    padding-left: 12px;
    padding-right: 12px;
`
const Title = styled.div`
    color: ${(props) => (props.red ? 'red' : '#767e93')};
`
const Right = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 8px 16px;
`
const TotalNum = styled.div`
    margin-left: 8px;
    color: #00a6f3;
`
const TotalTitle = styled.div``
const TimeoutWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
`
const TimeoutNum = styled.div`
    color: red;
`
const TimeoutTitle = styled.div``
const TotalWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
`

function OrderStatusCard({ hasMargin, data, index, active, onClick, title }) {
    return (
        <Wrap hasMargin={hasMargin} active={active} onClick={() => onClick(index)}>
            <Left>
                <Title red={index === 0}>{title}</Title>
            </Left>
            <Right>
                <TotalWrap>
                    <TotalTitle>總單量</TotalTitle>
                    <TotalNum>{data.stationOrderStatusSum}</TotalNum>
                </TotalWrap>
                <TimeoutWrap>
                    <TimeoutTitle>超時</TimeoutTitle>
                    <TimeoutNum>{data.timeOut}</TimeoutNum>
                </TimeoutWrap>
            </Right>
        </Wrap>
    )
}

export default OrderStatusCard
