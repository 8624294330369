import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    background-color: #fff;
    border-radius: 8px;
    width: 320px;
    height: 200px;
    margin-right: ${(props) => (props.hasMargin ? '8px' : '0')};
    border: 2px solid ${(props) => (props.active ? '#00a6f3' : 'transparent')};
`
const Top = styled.div`
    border-bottom: 1px solid #e1e1e1;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
`
const Avatar = styled.div`
    height: 70px;
    width: 70px;
    border-radius: 4px;
    background-color: #00a6f3;
    margin-right: 16px;
    background-image: url(${(props) => props.avatar});
    background-size: contain;
    background-repeat: no-repeat;
`
const Info = styled.div`
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`
const Name = styled.div``
const Phone = styled.div`
    font-size: 14px;
    color: #00a6f3;
`
const TagWrap = styled.div``
const Tag = styled.span`
    background-color: #fcd24d;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 12px;
`
const MoneyWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`
const Money = styled.div``
const Distance = styled.div`
    color: #cecece;
`
const Bottom = styled.div`
    background-color: #fefefe;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 8px 16px;
`
const Start = styled.div`
    border-bottom: 1px solid #efeff4;
`
const End = styled.div``
const Title = styled.div`
    font-size: 12px;
    color: #c2c4ca;
`
const Address = styled.div`
    color: #262e40;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

function OrderCard({ data, hasMargin, active, onClick }) {
    return (
        <Card hasMargin={hasMargin} active={active} onClick={() => onClick(data.id)}>
            <Top>
                <Avatar avatar={data.businessData.logo}></Avatar>
                <Info>
                    <Name>{data.consumerData.consumerName}</Name>
                    <Phone>{data.consumerData.consumerAddressTel}</Phone>
                    <TagWrap>
                        <Tag>收現金：${data.bussinessOrderMoney.toFixed(2)}</Tag>
                    </TagWrap>
                </Info>
                <MoneyWrap>
                    <Money>${data.bussinessOrderMoney.toFixed(2)}</Money>
                    <Distance>
                        {data.distance > 1000 ? (data.distance / 1000).toFixed(2) + 'km' : data.distance + 'm'}
                    </Distance>
                </MoneyWrap>
            </Top>
            <Bottom>
                <Start>
                    <Title>起點</Title>
                    <Address
                        title={`${data.businessData.bussinessStoreAddress} - ${data.businessData.bussinessStoreName}`}
                    >{`${data.businessData.bussinessStoreAddress} - ${data.businessData.bussinessStoreName}`}</Address>
                </Start>
                <End>
                    <Title>終點</Title>
                    <Address title={data.consumerData.consumerAddressInfo}>
                        {data.consumerData.consumerAddressInfo}
                    </Address>
                </End>
            </Bottom>
        </Card>
    )
}

export default OrderCard
