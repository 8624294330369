import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div``

function RiderManage() {
    return <Wrap>rider manage</Wrap>
}

export default RiderManage
