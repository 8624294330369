import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'

import { useStore } from '../../../util/useStore'
import OrderStatusCard from './OrderStatusCard'

const Wrap = styled.div``
const Title = styled.div`
    margin-bottom: 12px;
    font-weight: bold;
    color: #8f8fa7;
    font-size: 14px;
`
const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const titles = [
    { title: '未派' },
    { title: '騎手未接單' },
    { title: '騎手已接單' },
    { title: '騎手到店' },
    { title: '騎手離店' },
    { title: '到目的地' },
    { title: '搞掂' },
    { title: '異常' },
]

function OrderStatus({ onClickStatus }) {
    const { orderStore } = useStore()
    const handleClick = (index) => {
        onClickStatus(index)
    }
    useEffect(() => {
        orderStore.getAllOrderStatus()
        // eslint-disable-next-line
    }, [])
    return useObserver(() => {
        return (
            <Wrap>
                <Title>訂單總覽</Title>
                <Content>
                    {titles.map((v, i) => (
                        <OrderStatusCard
                            key={i}
                            data={
                                orderStore.gettingOrderStatus
                                    ? { stationOrderStatusSum: 0, timeOut: 0 }
                                    : orderStore.allOrderStatus[i]
                            }
                            title={v.title}
                            hasMargin={i !== titles.length - 1}
                            index={i}
                            active={i === orderStore.currentStatus}
                            onClick={handleClick}
                        ></OrderStatusCard>
                    ))}
                </Content>
            </Wrap>
        )
    })
}

export default OrderStatus
