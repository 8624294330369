import axios from 'axios'

export const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '/api' : '',
    // baseURL: '',
})

if (JSON.parse(localStorage.getItem('token'))) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('token'))}`
}

const request = {
    get: async (url, data) => {
        try {
            const res = await instance.get(url, {
                params: data,
            })
            return res.data
        } catch (error) {
            return Promise.reject(error.response.data)
        }
    },
    post: async (url, data) => {
        try {
            const res = await instance.post(url, data)
            return res.data
        } catch (error) {
            return Promise.reject(error.response.data)
        }
    },
    put: async (url, data) => {
        try {
            const res = await instance.put(url, data)
            return res.data
        } catch (error) {
            return Promise.reject(error.response.data)
        }
    },
    del: async (url) => {
        try {
            const res = await instance.delete(url)
            return res.data
        } catch (error) {
            return Promise.reject(error.response.data)
        }
    },
}

const login = {
    postMessage: (data) => request.post('/v1/web/set_message', data),
    postLogin: (data) => request.post('/v1/web/login', data),
    getUserInfo: (data) => request.get('/v1/web/user/details', data),
    postLogout: () => request.post('/v1/web/logout'),
}

const order = {
    getAllOrderStatus: (data) => request.get('/v1/status_order/getStationOrderStatus', data),
    getOrderList: (data) => request.get('/v1/status_order/getStationOrderList', data),
    getNearRiderList: (data) => request.get('/v1/status_order/getNearRiderList', data),
    getRiderList: (data) => request.get('/v1/status_order/getRiderList', data),
    getRiderListNew: (data) => request.get('/v1/status_order/rider/list', data),
    getAlongOrder: (data) => request.get('/v1/status_order/OnTheWayList', data),
    dispacthOrder: (data) => request.post('/v1/status_order/sendOrderToRider', data),
}

export default {
    login,
    order,
}
