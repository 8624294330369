import { observable, action } from 'mobx'

import api from '../api'
import { loginStore } from './login'
import Toast from '../component/Toast'

class OrderStore {
    @observable currentStatus = 0
    @observable gettingOrderStatus = true
    @observable allOrderStatus = []
    @observable gettingOrderList = true
    @observable orderList = []
    @observable nearRiderList = []
    @observable otherRiderList = []
    @observable gettingAlongOrder = true
    @observable alongOrder = []
    @observable shouldInitOrderList = true

    // 切換當前的訂單狀態標籤
    @action changeCurrentStatus(index) {
        this.currentStatus = index
        this.getOrderList({
            stationOrderStatus: this.allOrderStatus[index].stationOrderStatus,
            regionId: loginStore.userInfo.region.id,
        })
    }

    // 獲取所有的訂單狀態
    @action async getAllOrderStatus() {
        const res = await api.order.getAllOrderStatus({ regionId: loginStore.userInfo.region.id })
        this.allOrderStatus = res.data
        this.gettingOrderStatus = false
    }

    // 獲取當前訂單狀態的訂單列表
    @action async getOrderList(data) {
        this.gettingOrderList = true
        const res = await api.order.getOrderList(data)
        this.orderList = res.data
        this.gettingOrderList = false
    }

    // 根據所選訂單獲取推薦車手和其他車手
    @action async getRiderListNew(data) {
        const res = await api.order.getRiderListNew(data)
        if (res.success) {
            this.nearRiderList = res.data.nearRiderList
            this.otherRiderList = res.data.AllRider
        }
    }

    // 根據所選訂單和所選車手獲取順路單
    @action async getAlongOrder(data) {
        this.gettingAlongOrder = true
        const res = await api.order.getAlongOrder(data)
        if (res.success) {
            this.gettingAlongOrder = false
            this.alongOrder = res.data
        }
    }

    // 派單
    @action async dispatchOrder(data) {
        const res = await api.order.dispacthOrder(data)
        if (res.success) {
            Toast.success('指派ok')
        } else {
            Toast.warning(res.message)
        }
    }
}

export const orderStore = new OrderStore()
