import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AMapLoader from '@amap/amap-jsapi-loader'

const Wrap = styled.div`
    width: 100%;
    height: 100%;
`
const MapContainer = styled.div`
    width: 100%;
    height: 100%;
`

function Map({
    businessLongitude,
    bussinessLatitude,
    consumerLongitude,
    consumerLatitude,
    riderLongitude,
    riderLatitude,
    logo,
}) {
    const [map, setMap] = useState(null)
    const [AMap, setAMap] = useState(null)
    const [riding1, setRiding1] = useState(null)
    const [riding2, setRiding2] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        AMapLoader.load({
            key: 'e1e00abec9259d1aebd3ffe297241bf9', // 申请好的Web端开发者Key，首次调用 load 时必填
            version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            // plugins: ['AMap.ToolBar', 'AMap.Riding', 'AMap.Walking', 'AMap.CircleEditor', 'AMap.InfoWindow'], //插件列表
            plugins: ['AMap.Riding'], //插件列表
        })
            .then((AMap) => {
                let map = new AMap.Map('map-container', {
                    zoom: 14, //级别
                    center: [riderLongitude, riderLatitude], //中心点坐标
                })
                let riding1 = new AMap.Riding({
                    map: map,
                    autoFitView: false,
                    hideMarkers: true,
                })
                setRiding1(riding1)
                let riding2 = new AMap.Riding({
                    map: map,
                    autoFitView: false,
                    hideMarkers: true,
                })
                setRiding2(riding2)
                setMap(map)
                setAMap(AMap)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
            })
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!loading) {
            let riderPosition = new AMap.LngLat(riderLongitude, riderLatitude)
            let consumerPosition = new AMap.LngLat(consumerLongitude, consumerLatitude)
            let businessPosition = new AMap.LngLat(businessLongitude, bussinessLatitude)
            let riderMarker = new AMap.Marker({
                position: riderPosition,
                icon: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
            })
            let consumerMarker = new AMap.Marker({
                position: consumerPosition,
                icon: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
            })
            let businessMarker = new AMap.Marker({
                position: businessPosition,
                icon: new AMap.Icon({
                    imageSize: new AMap.Size(30, 30),
                    image: logo,
                }),
            })
            map.clearMap()
            map.add([riderMarker, consumerMarker, businessMarker])
            map.setFitView([riderMarker, consumerMarker, businessMarker], true, [60, 60, 60, 60], 20)
            riding1.search(riderPosition, businessPosition)
            riding2.search(businessPosition, consumerPosition)
        }
        // eslint-disable-next-line
    }, [loading])
    return (
        <Wrap>
            <MapContainer id='map-container'></MapContainer>
        </Wrap>
    )
}

export default Map
