import React from 'react'
import { NavLink } from 'react-router-dom'

function StyledNavLink({ className, children, ...props }) {
    return (
        <NavLink activeClassName={className} {...props}>
            {children}
        </NavLink>
    )
}

export default StyledNavLink
