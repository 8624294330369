import React, { useRef } from 'react'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'
import { useHistory } from 'react-router-dom'

import { useStore } from '../../util/useStore'

const Container = styled.div`
    position: absolute;
    padding: 10px;
    z-index: 20;
    top: 64px;
    right: 27px;
    user-select: none;
    box-shadow: rgba(16, 36, 94, 0.4) 0 2px 6px 0;
    border-radius: 4px;
`

const MyLogOut = styled.button`
    line-height: 28px;
    text-align: center;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    font-size: 12px;
    color: #666;
    padding: 0 12px;
    outline: none;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.1s linear;
    &:hover {
        border-color: #cb1d32;
        color: #cb1d32;
    }
`

function Menu(props) {
    const { loginStore } = useStore()
    const history = useHistory()
    const ref = useRef(null)
    useOnClickOutside(ref, props.close)

    const handleClickLogOut = () => {
        loginStore.postLogout()
        history.push('/login')
    }

    return (
        <Container ref={ref}>
            <MyLogOut onClick={handleClickLogOut}>退出登录</MyLogOut>
        </Container>
    )
}

export default Menu
